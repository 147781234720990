import React from 'react';

const BodyTextContentContainer = ({ children }) => {
  return (
    <div className='mt-8 mb-16 px-6 md:px-24 lg:my-16 lg:px-32 mega:mt-24 mega:mb-40 mega:px-48'>
      {children}
    </div>
  );
};

export default BodyTextContentContainer;

import React from 'react';

const LeftUpSlantContainer = ({ children, bgndColorClassName }) => {
  let bgndColor = 'bg-denimblue';
  if (bgndColorClassName) {
    bgndColor = bgndColorClassName;
  }
  return (
    <div
      className={`top-slant slant-margin-sm mb-8 flex flex-col items-center py-16 text-center text-white md:px-8 lg:py-24 mega:py-32 ${bgndColor}`}
    >
      {children}
    </div>
  );
};

export default LeftUpSlantContainer;

import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import LinkButton from '../components/linkButton';
import HeroContent from '../components/heroContent';
import { linkResolver } from '../utils/linkResolver';
import SectionTitle from '../components/sectionTitle';
import SectionTextContent from '../components/sectionTextContent';
import BodyTextContentContainer from '../components/containers/bodyTextContentContainer';
import LeftUpSlantContainer from '../components/containers/LeftUpSlantContainer';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';

const BasicImagesContainer = ({ imageFields }) => {
  return (
    <div className='flex w-full flex-row flex-wrap items-center justify-center lg:mt-10 mega:mt-12 mega:px-40'>
      {imageFields.map((field, index) => {
        const imageFluid = field.image.gatsbyImageData;
        const alt = field.image.alt;
        const url = field.image.url;
        return (
          <div
            key={index}
            className='m-1 h-full w-full lg:my-4 lg:mx-0 lg:w-1/2'
          >
            <a href={url} rel='noopened noreferrer' target='_blank'>
              <GatsbyImage
                image={imageFluid}
                alt={alt}
                className='mx-2 object-cover shadow-lg'
                style={{ maxHeight: `400px` }}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

const PlayscapesPage = ({ data }) => {
  const {
    hero_title,
    banner_image,
    banner_graphic,
    banner_description_content,
    body,
    section_title,
    section_text,
    call_to_action_link_text,
    next_page_link,
  } = data.prismicPlayscapes.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const ctaLink = linkResolver(next_page_link);
  const callToActionText = RichText.asText(call_to_action_link_text.richText);

  const firstPhotoGroup = body[0];
  const secondPhotoGroup = body[1];
  const thirdPhotoGroup = body[2];
  const fourthPhotoGroup = body[3];
  const fifthPhotoGroup = body[4];
  const sixthPhotoGroup = body[5];

  const muralEqualHalvesFields = [
    fifthPhotoGroup.items[0],
    fifthPhotoGroup.items[1],
  ];
  const muralEqualQuartersFields = [
    fifthPhotoGroup.items[2],
    fifthPhotoGroup.items[3],
    fifthPhotoGroup.items[4],
    fifthPhotoGroup.items[5],
  ];
  const muralWeightedFields = [
    fifthPhotoGroup.items[6],
    fifthPhotoGroup.items[7],
  ];

  const weightedFieldsContentFunc = () => {
    const firstWeightImageField = muralWeightedFields[0];
    const secontWeightImageField = muralWeightedFields[1];

    return (
      <div className='md:flex-no-wrap flex flex-col md:flex-row'>
        <div className='my-1 h-full w-full md:m-1 lg:my-4 lg:mx-0 lg:w-3/4'>
          <a
            href={firstWeightImageField.url}
            rel='noopened noreferrer'
            target='_blank'
          >
            <GatsbyImage
              image={firstWeightImageField.gatsbyImageData}
              alt={firstWeightImageField.alt}
              className='mx-2 object-cover shadow-lg'
              style={{ maxHeight: `400px` }}
            />
          </a>
        </div>
        <div className='my-1 h-full w-full md:m-1 lg:my-4 lg:mx-0 lg:w-1/4'>
          <a
            href={firstWeightImageField.url}
            rel='noopened noreferrer'
            target='_blank'
          >
            <GatsbyImage
              image={secontWeightImageField.gatsbyImageData}
              alt={firstWeightImageField.alt}
              className='mx-2 object-cover shadow-lg'
              style={{ maxHeight: `400px` }}
            />
          </a>
        </div>
      </div>
    );
  };

  const muralsContent = (
    <div className='w-full lg:mt-10 mega:mt-12 mega:px-40'>
      <div className='md:flex-no-wrap flex flex-row'>
        {muralEqualHalvesFields.map((field, halvesIndex) => {
          const imageFluid = field.image.gatsbyImageData;
          const alt = field.image.alt;
          const url = field.image.url;
          return (
            <div
              key={halvesIndex}
              className='m-1 h-full w-full lg:my-4 lg:mx-0 lg:w-1/2'
            >
              <a href={url} rel='noopened noreferrer' target='_blank'>
                <GatsbyImage
                  image={imageFluid}
                  alt={alt}
                  className='mx-2 object-cover'
                  style={{ maxHeight: `400px` }}
                />
              </a>
            </div>
          );
        })}
      </div>
      <div className='md:flex-no-wrap flex flex-row flex-wrap'>
        {muralEqualQuartersFields.map((field, quartersIndex) => {
          const imageFluid = field.image.gatsbyImageData;
          const alt = field.image.alt;
          const url = field.image.url;
          return (
            <div
              key={quartersIndex}
              className='m-1 h-full w-full lg:my-4 lg:mx-0 lg:w-1/4'
            >
              <a href={url} rel='noopened noreferrer' target='_blank'>
                <GatsbyImage
                  image={imageFluid}
                  alt={alt}
                  className='mx-2 object-cover'
                  style={{ maxHeight: `400px` }}
                />
              </a>
            </div>
          );
        })}
      </div>
      {weightedFieldsContentFunc()}
    </div>
  );

  return (
    <Layout
      pageTitle={RichText.asText(hero_title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='hero-slant lg:hero-slant-lg'
      contentInsideBackground={() => (
        <FullWidthContainer className='pt-4 md:mt-8 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(hero_title.richText)}
            heroDescription={banner_description_content.richText}
            rightAlign
          />
        </FullWidthContainer>
      )}
    >
      <BodyTextContentContainer>
        <SectionTitle
          text={RichText.asText(section_title.richText)}
          usePrimaryTextColor
        />
        <SectionTextContent text={section_text.richText} />
      </BodyTextContentContainer>

      <LeftUpSlantContainer bgndColorClassName='bg-blue-800'>
        <SectionTitle
          text={RichText.asText(
            firstPhotoGroup.primary.photo_group_title.richText
          )}
          useWhiteTextColor
        />
        <BasicImagesContainer imageFields={firstPhotoGroup.items} />
      </LeftUpSlantContainer>
      <div className='top-slant slant-margin-sm mb-8 flex flex-col items-center pt-20 text-center text-white md:px-8 lg:py-24 mega:py-32'>
        <SectionTitle
          text={RichText.asText(
            secondPhotoGroup.primary.photo_group_title.richText
          )}
          usePrimaryTextColor
        />
        <BasicImagesContainer imageFields={secondPhotoGroup.items} />
      </div>
      <div className='left-angled-down-right-bottom-only mb-8 flex flex-col items-center bg-denimblue py-8 text-center md:px-8 md:pb-16 lg:pt-16 lg:pb-16'>
        <SectionTitle
          text={RichText.asText(
            thirdPhotoGroup.primary.photo_group_title.richText
          )}
          useWhiteTextColor
        />
        <BasicImagesContainer imageFields={thirdPhotoGroup.items} />
      </div>
      <div className='top-slant slant-margin-sm mb-8 flex flex-col items-center pt-12 text-center text-white md:px-8 lg:py-24 mega:py-32'>
        <SectionTitle
          text={RichText.asText(
            fourthPhotoGroup.primary.photo_group_title.richText
          )}
          usePrimaryTextColor
        />
        <BasicImagesContainer imageFields={fourthPhotoGroup.items} />
      </div>
      <div className='left-angled-down-right-top-only -mt-8 mb-8 flex flex-col items-center bg-blue-800 pt-12 text-center md:-mt-10 md:px-8 lg:-mt-16 lg:py-24 mega:-mt-20 mega:py-32'>
        <SectionTitle
          text={RichText.asText(
            fifthPhotoGroup.primary.photo_group_title.richText
          )}
          useWhiteTextColor
        />
        {muralsContent}
      </div>
      <div className='top-slant slant-margin-sm mb-8 flex flex-col items-center pt-12 text-center text-white md:mb-16 md:px-8 lg:py-24 mega:py-32'>
        <SectionTitle
          text={RichText.asText(
            sixthPhotoGroup.primary.photo_group_title.richText
          )}
          usePrimaryTextColor
        />
        <BasicImagesContainer imageFields={sixthPhotoGroup.items} />
      </div>
      <LeftUpSlantContainer>
        <div className='mt-12 w-full text-center md:w-1/2 lg:w-1/3'>
          <LinkButton to={ctaLink.url} label={callToActionText} />
        </div>
      </LeftUpSlantContainer>
    </Layout>
  );
};

export const query = graphql`
  query PlayscapesPageQuery {
    prismicPlayscapes {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          alt
          gatsbyImageData
          url
        }
        banner_image {
          alt
          gatsbyImageData
          url
        }
        call_to_action_link_text {
          richText
        }
        hero_title {
          richText
        }
        next_page_link {
          url
          uid
        }
        section_text {
          richText
        }
        section_title {
          richText
        }
        body {
          ... on PrismicPlayscapesDataBodyPhotos {
            items {
              image {
                alt
                gatsbyImageData
                url
              }
            }
            primary {
              photo_group_title {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default PlayscapesPage;
